import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1 className="Title">Countless Holdings LLC</h1>
      <header className="App-header"></header>
      <body>
        <img src={logo} className="App-logo" alt="logo" />

        <a
          className="App-link"
          href={"mailto: countless.holdings@gmail.com"}
          target="_blank"
          rel="noopener noreferrer"
        >
          contact
        </a>
      </body>
    </div>
  );
}

export default App;
